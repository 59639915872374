import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { RiLoader5Line } from "react-icons/ri"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  LayoutQuoting,
  Title,
  SectionCenter,
  PlanDisplayCard,
  PrimaryButton,
  SecondaryButton,
  GoBack,
  PaymentCard,
} from "../../components"
import { getCurrentPath, setPayment } from "../../redux/actions"
import {
  validPaymentInfo,
  validPayment,
  setPurchasePayload,
} from "../../utils/helpers"

const Payment = ({
  location,
  getCurrentPath,
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
}) => {
  const intl = useIntl()
  const [isValidPlan, setIsValidPlan] = useState(true)
  const [isValidPaymentInfo, setIsValidPaymentInfo] = useState(true)
  const [submitted, setSubmitted] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidPlan(selectedPlan !== null)
    setIsValidPaymentInfo(validPaymentInfo())
  }, [getCurrentPath, location.pathname])

  const onMakePurchase = () => {
    const purchasePayload = setPurchasePayload(
      typeof window !== "undefined" && window.sessionStorage.getItem("address"),
      typeof window !== "undefined" && window.sessionStorage.getItem("city"),
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("province"),
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("postalCode"),
      typeof window !== "undefined" && window.sessionStorage.getItem("email"),
      typeof window !== "undefined" && window.sessionStorage.getItem("phone"),
      travelPlan,
      travellers,
      coverage,
      deductible,
      purchaseConfirmation,
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("cardHolderName"),
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("cardNumber"),
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("expireMonth"),
      typeof window !== "undefined" &&
        window.sessionStorage.getItem("expireYear"),
      typeof window !== "undefined" && window.sessionStorage.getItem("cvc"),
      famRate,
      options,
      selectedPlan.insurer,
      quotes
    )
    dispatch(setPayment(purchasePayload, selectedPlan.insurer))
    setSubmitted(true)
  }

  useEffect(() => {
    if (selectedPlan === null) return
    if (
      purchaseResult[selectedPlan.insurer] &&
      Object.keys(purchaseResult[selectedPlan.insurer]).length !== 0 &&
      purchaseResult[selectedPlan.insurer].error === null
    )
      navigate("/get-quote-purchase/success")
    if (
      !purchaseResult[selectedPlan.insurer] ||
      (Object.keys(purchaseResult[selectedPlan.insurer]).length !== 0 &&
        purchaseResult[selectedPlan.insurer].error !== null)
    )
      navigate("/get-quote-purchase/fail")
  }, [purchaseResult])

  return (
    <LayoutQuoting>
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidPlan && isValidPaymentInfo ? (
          <>
            <Title
              title={intl.formatMessage({ id: "purchase.title" })}
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  display: grid;
                  row-gap: 1.5rem;
                `}
              >
                {selectedPlan && <PlanDisplayCard />}
                <PaymentCard />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  margin-top: 4rem;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  onClick={onMakePurchase}
                  disabled={!validPayment() || !canProceedFromUI || submitted}
                >
                  {submitted ? (
                    <span>
                      <IconAnimated />
                    </span>
                  ) : (
                    intl.formatMessage({ id: "btn.purchase" })
                  )}
                </PrimaryButton>
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={() => navigate("/get-quote-purchase/payment-info")}
                >
                  {intl.formatMessage({ id: "btn.goback" })}
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-policies"
            notFilledOut={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.missingItem1" })
                : intl.formatMessage({ id: "redirect.missingItem4" })
            }
            text={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.text1" })
                : intl.formatMessage({ id: "redirect.text2" })
            }
            actionText={
              !isValidPlan
                ? intl.formatMessage({ id: "redirect.action1" })
                : intl.formatMessage({ id: "redirect.action2" })
            }
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
}) => ({
  selectedPlan,
  canProceedFromUI,
  travelPlan,
  travellers,
  coverage,
  deductible,
  purchaseConfirmation,
  purchaseResult,
  famRate,
  options,
  quotes,
})

const IconAnimated = styled(RiLoader5Line)`
  font-size: 1rem;
  color: var(--clr-neutral-100);

  animation: spin 0.75s linear infinite;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

export default connect(mapStateToProps, { getCurrentPath })(Payment)
